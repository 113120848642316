// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/more.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/less.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".faq-item[data-v-3a9f42b0]{border-radius:8px;padding:24px;transition:.3s;cursor:pointer;}.faq-item[data-v-3a9f42b0]:hover{--tw-bg-opacity:1;background-color:rgb(213 235 253 / var(--tw-bg-opacity));}.faq-item__wrapper[data-v-3a9f42b0]{min-height:0}.faq-item__question[data-v-3a9f42b0]{--tw-text-opacity:1;color:rgb(57 7 63 / var(--tw-text-opacity));font-weight:700;font-size:14px;line-height:18px;padding-right:50px;position:relative;}@media (min-width: 1024px){.faq-item__question[data-v-3a9f42b0]{font-size:16px;line-height:24px}}.faq-item__question[data-v-3a9f42b0]:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;right:0;top:0}.faq-item__wrapper-answer[data-v-3a9f42b0]{display:grid;grid-template-rows:0fr;transition:.3s;overflow:hidden;}.faq-item__wrapper-answer[data-v-3a9f42b0] h4{margin-top:16px;margin-bottom:10px}.faq-item__wrapper-answer[data-v-3a9f42b0] ul{list-style-type:disc}.faq-item__wrapper-answer[data-v-3a9f42b0] li{margin-left:20px}.faq-item__answer[data-v-3a9f42b0]{--tw-text-opacity:1;color:rgb(57 7 63 / var(--tw-text-opacity));font-weight:500;font-size:14px;line-height:18px;min-height:0;transition:margin-top .3s;}@media (min-width: 1024px){.faq-item__answer[data-v-3a9f42b0]{font-size:16px;line-height:24px;width:85%}}.faq-item--collapsed .faq-item__question[data-v-3a9f42b0]:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.faq-item--collapsed .faq-item__wrapper-answer[data-v-3a9f42b0]{grid-template-rows:1fr}.faq-item--collapsed .faq-item__answer[data-v-3a9f42b0]{margin-top:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
