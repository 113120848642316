
export default {
  props: {
    isH3: {
      type: Boolean,
      default: false,
    },
    titleAlt: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    faq: {
      type: Array,
      default: () => [
        {
          question:
            "Est-il possible de modifier mon site internet ALEO après sa réalisation ?",
          answer: `<p class="pb-4">Nous comprenons que votre entreprise est en constante évolution. Chez ALEO, <strong>nous concevons votre site internet</strong> et effectuons des modifications de contenu de manière ponctuelle en fonction de vos besoins.</p>
  <p class="pb-4">Que vous ayez besoin de rafraîchir certaines pages de votre site, d'ajuster votre contenu, ou de changer une image, notre équipe d'éditeurs de site est à votre service pour effectuer ces modifications. Avec un <strong>design responsive</strong>, votre site s'adapte parfaitement aux <strong>ordinateurs, tablettes et mobiles.</strong> </p>
  <p>Nous souhaitons que votre <strong>site internet</strong> reste une <strong>vitrine</strong> pertinente et efficace, alignée avec l'évolution de votre entreprise.</p>`,
          isCollapsed: false,
        },
        {
          question: "Quel est le prix de la création d’un site web ?",
          answer: `<p class="pb-4">Le <strong>coût de la conception d’un site web</strong> avec ALEO peut fluctuer en fonction de vos besoins spécifiques. En fonction de votre <strong>stratégie digitale</strong>, du <strong>nombre de pages</strong> de votre site, et de l'<strong>optimisation</strong> pour le référencement que vous souhaitez, le prix peut varier.</p>
  <p>Pour toute demande concernant nos tarifs, nous vous invitons à cliquer sur le bouton "recevoir nos tarifs" ou à prendre rendez-vous avec un de nos conseillers.`,
          isCollapsed: false,
        },
        {
          question:
            "Pour la refonte de mon site internet, puis-je me tourner vers ALEO ?",
          answer: `<p class="pb-4">En tant qu'<strong>agence web professionnelle</strong>, ALEO est à votre disposition pour vous accompagner dans la <strong>refonte de votre site existant</strong>. Si vous avez déjà créé un site internet dont vous souhaitez garder le contenu, nos experts en <strong>développement web</strong> s'occuperont de cette transformation.</p>
  <p>Nous prenons en charge la récupération des éléments que vous souhaitez conserver pour votre nouveau site, et nous nous engageons à réaliser une transition fluide entre votre ancien et votre <strong>nouveau site web</strong>. Le but est de conserver votre image de marque tout en apportant les améliorations nécessaires à son optimisation.</p>`,
          isCollapsed: false,
        },
        //       {
        //         question: "Qu’est-ce que le référencement naturel ?",
        //         answer: `<p class="pb-4">Le <strong>référencement naturel</strong>, aussi connu sous le nom de SEO, est l'ensemble des techniques visant à améliorer la position de votre site internet dans les <strong>résultats de recherche Google</strong>. Autrement dit, il s'agit d'optimiser la visibilité de votre site internet de manière organique, sans avoir à recourir à la publicité payante.</p>
        // <p class="pb-4">Le référencement naturel comprend <strong>l'optimisation</strong> de différents aspects de votre site web, tant au niveau <strong>technique</strong> que du <strong>contenu</strong>. Cela inclut l'utilisation de mots-clés pertinents, l'optimisation de la structure du site, l'amélioration de la <strong>vitesse de chargement</strong>, la création de contenus de qualité et le développement de <strong>liens externes</strong> organiques.</p>
        // <p class="pb-4">L'objectif principal du référencement naturel est d'améliorer la <strong>visibilité</strong> d'un site web dans les <strong>résultats de recherche</strong>, ce qui permet d'attirer un trafic qualifié et d'augmenter le nombre de visiteurs. En se classant parmi les <strong>premiers résultats pour des mots-clés pertinents</strong>, un site a plus de chances d'être visité et de générer du trafic organique.</p>
        // <p>Le référencement naturel est un processus continu qui nécessite une surveillance et une adaptation constantes aux <strong>algorithmes des moteurs de recherche</strong>. Il peut également prendre du temps pour obtenir des résultats significatifs, car le classement d'un site web dépend de nombreux facteurs et de la concurrence existante sur ces mots-clés.</p>`,
        //         isCollapsed: false,
        //       },
        //       {
        //         question:
        //           "Proposez-vous une recherche de mots clés et une optimisation de contenu ?",
        //         answer: `<p class="pb-4">Pour chaque site internet que nous développons, nous mettons en place une <strong>stratégie de référencement</strong>. Lors de la prise de brief, nous prenons le temps de comprendre votre activité et vos besoins pour identifier les <strong>mots-clés</strong> les plus pertinents pour votre <strong>référencement</strong>.</p>
        // <p>Un <strong>rédacteur</strong> dédié rédige ensuite le contenu de votre site internet, en optimisant l'utilisation de ces mots-clés. Enfin, le développeur web en charge de la réalisation de votre site web assure une <strong>optimisation technique</strong> pour qu'il soit conforme aux critères de référencement de Google.</p>`,
        //         isCollapsed: false,
        //       },
        //       {
        //         question:
        //           "Fournissez-vous des rapports de performance SEO réguliers ?",
        //         answer: `<p class="pb-4">Absolument ! Si vous choisissez ALEO pour <strong>développer votre site internet</strong> professionnel, vous aurez accès à un tableau de performances vous permettant de suivre l'évolution de votre positionnement SEO et l'état de santé de votre site internet.</p>
        // <p>De plus, un expert dédié à votre projet sera toujours disponible pour vous accompagner et assurer un suivi optimal de votre projet web.</p>`,
        //         isCollapsed: false,
        //       },
        {
          question:
            "Pourquoi faire appel à une agence de création de sites web comme ALEO ?",
          answer: `<p class="pb-4"><strong>Créer un site internet</strong> à l'aide d'une <strong>agence</strong> comme ALEO offre de nombreux avantages. Notre objectif principal est d'accompagner votre entreprise tout au long de son <strong>évolution digitale</strong>. Ainsi, nous veillons à ce que votre site internet reste à jour et pertinent en permanence.</p>
  <p class="pb-4">Nos équipes sont prêtes à effectuer toutes les <strong>modifications</strong> nécessaires à votre <strong>contenu, images</strong>, et même assurer la <strong>sécurité</strong> de votre site avec un certificat <strong>SSL</strong>. Nous nous occupons également de l'hébergement web de votre site sur des <strong>serveurs sécurisés</strong>, 100 % français.</p>
  <p>En outre, ALEO est là pour vous aider dans la <strong>refonte de votre site</strong> existant. Si vous avez déjà un site internet et que vous souhaitez conserver vos contenus, nos équipes d'experts en <strong>développement de sites web</strong> s'occupent de tout.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Proposez-vous des designs personnalisés ou utilisez-vous des templates ?",
          answer: `<p class="pb-4">Chez ALEO, chaque <strong>création de site web</strong> est un projet unique. Nos <strong>sites vitrine</strong> sont 100 % personnalisés et adaptés à votre activité. Nous prenons en compte vos préférences pour l'organisation de vos <strong>textes</strong>, le design des <strong>éléments</strong>, les <strong>couleurs</strong> que vous souhaitez mettre en avant, et toute votre identité visuelle.</p>
  <p>Chaque <strong>conception de site web</strong> est réalisée en parfaite adéquation avec les besoins et les attentes de chaque client. C'est pourquoi, dès le début de chaque projet, un expert ALEO organise une prise de brief pour mieux cerner vos attentes.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Est-ce qu'ALEO peut créer des sites internet dans toute la France ?",
          answer: `<p class="pb-4">Bien sûr, l'agence ALEO peut accompagner des professionnels dans toute la France, voici quelques localités où ALEO a déjà créé des sites internet pour des entreprises locales : <a href="https://www.aleo.agency/site-internet-marseille/">Marseille</a>,
<a href="https://www.aleo.agency/site-internet-toulouse/" rel="dofollow">Toulouse</a>,
<a href="https://www.aleo.agency/site-internet-grenoble/" rel="dofollow">Grenoble</a>,
<a href="https://www.aleo.agency/site-internet-paris/" rel="dofollow">Paris</a>,
<a href="https://www.aleo.agency/site-internet-lyon/" rel="dofollow">Lyon</a>,
<a href="https://www.aleo.agency/site-internet-nice/" rel="dofollow">Nice</a>,
<a href="https://www.aleo.agency/site-internet-nantes/" rel="dofollow">Nantes</a>,
<a href="https://www.aleo.agency/site-internet-perpignan/" rel="dofollow">Perpignan</a>,
<a href="https://www.aleo.agency/site-internet-montpellier/" rel="dofollow">Montpellier</a>,
<a href="https://www.aleo.agency/site-internet-toulon/" rel="dofollow">Toulon</a>,
<a href="https://www.aleo.agency/site-internet-angers/" rel="dofollow">Angers</a>,
<a href="https://www.aleo.agency/site-internet-mulhouse/" rel="dofollow">Mulhouse</a>,
<a href="https://www.aleo.agency/site-internet-rennes/" rel="dofollow">Rennes</a>,
<a href="https://www.aleo.agency/site-internet-alsace/" rel="dofollow">en Alsace</a>,
<a href="https://www.aleo.agency/site-internet-colmar/" rel="dofollow">Colmar</a>,
<a href="https://www.aleo.agency/site-internet-caen/" rel="dofollow">Caen</a>,
<a href="https://www.aleo.agency/site-internet-vannes/" rel="dofollow">Vannes</a>,
<a href="https://www.aleo.agency/site-internet-lille/" rel="dofollow">Lille</a>,
<a href="https://www.aleo.agency/site-internet-limoges/" rel="dofollow">Limoges</a>,
<a href="https://www.aleo.agency/site-internet-vendee/" rel="dofollow">en Vendée</a>,
<a href="https://www.aleo.agency/site-internet-bretagne/" rel="dofollow"> en Bretagne</a>,
<a href="https://www.aleo.agency/site-internet-reims/" rel="dofollow">Reims</a>,
<a href="https://www.aleo.agency/site-internet-rouen/" rel="dofollow">Rouen</a> et bien d'autres !</p>`,
          isCollapsed: false,
        },
        {
          question: "Quels types de sites web pouvez-vous créer ?",
          answer: `<p class="pb-4">Chez ALEO, nous nous spécialisons exclusivement dans la création de <a href="https://www.aleo.agency/blog/pourquoi-creer-un-site-internet-vitrine-5-raisons/" rel="dofollow"><strong>sites vitrines</strong></a>. Ces sites sont idéaux pour mettre en valeur votre entreprise, vos services ou vos produits, et pour renforcer votre présence en ligne. Un site vitrine vous permet de <strong>communiquer efficacement</strong> avec vos clients potentiels en leur offrant une présentation claire et attrayante de votre activité. Chaque site est conçu de manière <strong>unique</strong> avec un <strong>design responsive</strong>, optimisé pour tous types d'appareils, qu'il s'agisse d'ordinateurs, de tablettes ou de smartphones. Nous travaillons en étroite collaboration avec vous pour que votre site vitrine reflète parfaitement l'image et les valeurs de votre marque.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Combien de temps faut-il pour créer un site web avec ALEO ?",
          answer: `<p class="pb-4">La création d’un site web chez ALEO s’effectue en plusieurs étapes, chacune cruciale pour garantir un résultat <strong>optimal</strong>. Dès que vous validez votre projet, nous vous fournissons une maquette de votre site internet <strong>sous un délai de 21 jours</strong>. Ensuite, la mise en ligne dépend de vos retours et des ajustements éventuels que vous souhaitez apporter. Nous veillons à ce que chaque détail soit conforme à vos attentes pour que le site final corresponde parfaitement à vos besoins et à votre vision.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Offrez-vous des services de référencement (SEO) pour améliorer la visibilité de mon site ?",
          answer: `<p class="pb-4">Absolument ! Chez ALEO, nous proposons des services de <a href="https://www.aleo.agency/referencement-site-internet/" rel="dofollow"><strong>référencement naturel (SEO)</strong></a> pour améliorer la visibilité de votre site web sur les moteurs de recherche comme Google. Notre objectif est de vous positionner en tête des résultats pour que votre site soit trouvé par ceux qui recherchent vos services ou produits. Une bonne optimisation SEO permet d’attirer plus de <strong>visiteurs qualifiés</strong>, augmentant ainsi vos chances de <strong>convertir</strong> ces visites en clients.</p>`,
          isCollapsed: false,
        },
        {
          question: "Proposez-vous des services d'hébergement web ?",
          answer: `<p class="pb-4">Tous les sites internet créés par ALEO sont hébergés sur des <strong>serveurs français 100 % sécurisés</strong>, garantissant la <strong>sécurité</strong> et la <strong>rapidité</strong> d'accès à votre site. Notre hébergement offre une protection optimale contre les attaques et une disponibilité permanente. Nous nous occupons également de la gestion des <strong>mises à jour</strong> et de la <strong>maintenance</strong>, afin que vous puissiez vous concentrer sur le développement de votre activité en toute tranquillité.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Puis-je voir des exemples de sites web que vous avez créés ?",
          answer: `<p class="pb-4">Si vous souhaitez découvrir les sites web que nous avons créés pour nos clients, consultez les captures d'écran présentes sur notre site. Vous y trouverez des <strong>exemples</strong> de notre travail. De plus, l'onglet "Témoignages" vous permet de lire les avis de nos clients sur leur expérience avec ALEO. Vous aurez ainsi une vision complète de la <strong>qualité</strong> de nos réalisations et de notre <strong>engagement</strong> envers la <strong>satisfaction client</strong>.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Quels outils ou technologies utilisez-vous pour la création des sites web ?",
          answer: `<p class="pb-4">Chez ALEO, nous utilisons <a href="https://www.aleo.agency/blog/quest-ce-quun-cms-content-management-system/" rel="dofollow"><strong>WordPress</strong></a> comme CMS (Content Management System) pour concevoir vos sites web. WordPress offre une grande flexibilité et permet de personnaliser chaque aspect de votre site afin de répondre à vos besoins spécifiques. Cette technologie nous permet de créer des sites web entièrement personnalisables tout en assurant une gestion simple et efficace pour l'avenir.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Proposez-vous des formations pour que je puisse gérer mon site moi-même après sa création ?",
          answer: `<p class="pb-4">Nous ne proposons pas de formation spécifique pour la gestion de votre site après sa création. Toutefois, toutes nos offres incluent un <strong>forfait de modifications</strong> pour que vous puissiez nous confier l’actualisation de votre site sans vous en préoccuper. Cela vous permet de maintenir votre site à jour sans effort, tout en ayant l'assurance que chaque modification est effectuée par des <strong>experts</strong>.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Comment assurez-vous la sécurité des sites web que vous créez ?",
          answer: `<p class="pb-4">La sécurité de votre site web est une priorité chez ALEO. Nos sites sont <strong>régulièrement mis à jour</strong> pour éviter toute faille de sécurité. De plus, l’hébergement se fait sur des serveurs français hautement sécurisés, assurant une <strong>protection maximale</strong> contre les cyberattaques. Vous pouvez compter sur notre équipe de webmasters pour surveiller et protéger votre site en permanence, vous garantissant ainsi une tranquillité d’esprit totale.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Quels sont les coûts récurrents après la création du site (maintenance, mises à jour, etc.) ?",
          answer: `<p class="pb-4">Chez ALEO, nous mettons un point d'honneur à rendre la gestion de votre site aussi <strong>simple</strong> que possible. Une fois votre site créé, vous n'avez pas à vous soucier de frais supplémentaires pour des mises à jour ou des modifications mineures. Nos <a href="https://www.aleo.agency/blog/site-web-par-abonnement-la-solution-aleo/" rel="dofollow"><strong>offres</strong></a> incluent un forfait vous permettant de nous envoyer vos demandes de modifications <strong>sans surcoût</strong>. Cela vous permet de <strong>garder votre site à jour</strong> en toute sérénité.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Pouvez-vous intégrer des fonctionnalités spécifiques (paiement en ligne, réservation, forum, etc.) sur mon site ?",
          answer: `<p class="pb-4">Bien que les sites créés par ALEO ne contiennent pas de modules spécifiques tels que les paiements en ligne, réservations ou forums, nous pouvons cependant intégrer des liens directs vers vos plateformes externes comme <strong>Calendly</strong>, <strong>Planity</strong> ou toute autre solution que vous utilisez. Cette approche permet à vos visiteurs de réaliser leurs actions de manière fluide tout en maintenant une <strong>expérience utilisateur</strong> cohérente avec votre site.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Comment se passe le support client après la mise en ligne du site ?",
          answer: `<p class="pb-4">Une fois que votre site internet est mis en ligne, notre équipe reste <strong>à votre disposition</strong> pour répondre à toutes vos <strong>questions</strong> ou vos <strong>besoins de modifications</strong>. Le support client est une de nos priorités, et vous pouvez facilement nous joindre en appelant notre standard au <strong>09 74 90 28 64</strong>. Nous vous garantissons une réactivité optimale pour résoudre <strong>rapidement</strong> toute demande relative à votre site.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Est-ce que mon site sera responsive et compatible avec tous les appareils (mobile, tablette, desktop) ?",
          answer: `<p class="pb-4">Chez ALEO, nous nous assurons que tous les sites que nous créons soient <strong>entièrement responsive</strong>, c'est-à-dire qu'ils s’adaptent automatiquement à tous les types d'appareils (ordinateurs, tablettes et smartphones). Cela garantit une <strong>expérience utilisateur optimale</strong>, peu importe le dispositif utilisé par vos visiteurs. Un site responsive est essentiel pour garantir une navigation fluide et agréable à vos clients.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Offrez-vous des services de création de contenu (texte, images, vidéos) pour le site web ?",
          answer: `<p class="pb-4">Nos services de création de site internet incluent la <strong>rédaction</strong> de contenu sur-mesure pour vos pages, l'intégration de vos <strong>images</strong> ou d'images de <strong>banques professionnelles</strong>. Nous pouvons également intégrer vos vidéos afin d'enrichir l'expérience utilisateur et de dynamiser votre site. Chaque contenu sera minutieusement choisi pour correspondre au mieux à votre image et votre travail.</p>`,
          isCollapsed: false,
        },
        {
          question: "Quels types d’entreprises accompagnez-vous ?",
          answer: `<p class="pb-4">ALEO accompagne plusieurs types d’entreprises, voici une liste non exhaustive des secteurs et des activités que nous avons déjà accompagnées : <a href="https://www.aleo.agency/blog/creer-un-site-internet-de-couvreur-exemples-prix-et-delais/" rel="dofollow"><strong>couvreur</strong></a>, <a href="https://www.aleo.agency/blog/creer-un-site-internet-de-metallerie-ferronnerie-exemples-prix-et-delais/" rel="dofollow"><strong>métallerie et ferronnerie</strong></a>, <a href="https://www.aleo.agency/blog/creer-un-site-internet-de-psychologue-exemples-prix-et-delais/" rel="dofollow"><strong>psychologue</strong></a>, <a href="https://www.aleo.agency/blog/creer-un-site-internet-de-serrurier-exemples-prix-et-delais/" rel="dofollow"><strong>serrurier</strong></a>, <a href="https://www.aleo.agency/blog/creer-un-site-internet-pour-un-restaurant-exemples-prix-et-delais/" rel="dofollow"><strong>restaurant</strong></a>, <a href="https://www.aleo.agency/blog/creer-un-site-internet-delectricien-exemples-prix-et-delais/" rel="dofollow"><strong>électricien</strong></a>, <a href="https://www.aleo.agency/blog/creer-un-site-internet-de-carreleur-exemples-prix-et-delais/" rel="dofollow"><strong>carreleur</strong></a>, <a href="https://www.aleo.agency/blog/comment-creer-un-site-internet-de-taxi/" rel="dofollow"><strong>taxis</strong></a>, <a href="https://www.aleo.agency/blog/comment-creer-un-site-internet-de-photographe/" rel="dofollow"><strong>photographe</strong></a>, <a href="https://www.aleo.agency/blog/comment-creer-un-site-internet-d-avocat/" rel="dofollow"><strong>avocat</strong></a>, <a href="https://www.aleo.agency/blog/comment-creer-un-site-internet-de-chirurgien/" rel="dofollow"><strong>chirurgien</strong></a>, <a href="https://www.aleo.agency/blog/comment-creer-un-site-internet-de-plombier/" rel="dofollow"><strong>plombier</strong></a>.</p>`,
          isCollapsed: false,
        },
      ],
    },
  },
  data() {
    return {
      isDesktop: false,
    };
  },

  mounted() {
    this.watchMedia();
  },

  methods: {
    watchMedia() {
      window.matchMedia("(min-width: 1024px)").matches
        ? (this.isDesktop = true)
        : (this.isDesktop = false);
    },
  },
};
