
export default {
  props: {
    isH3: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [
        {
          question:
            "Quelles sont les étapes de la création de mon futur site internet ?",
          answer:
            "Dans un premier temps, un expert en communication numérique prend contact avec toi afin de recueillir un maximum d’informations sur ton activité et te conseillera sur les éléments à mettre en avant sur ton site. Préparation de base (spatiale) : nos webdesigners et rédacteurs prennent le relai sur ton site internet et t’envoient une première version. S’ensuivent échanges et remarques afin que ton site corresponde au max à tes cibles et ton activité. Décollage imminent : mise en ligne de ton site internet sur le nom de domaine public final. Pilotage automatique activé, direction les étoiles.",
          isCollapsed: false,
        },
        {
          question: "Comment confier la création de mon site web à ALEO ?",
          answer:
            "Tu souhaites confier la création de ton site vitrine à ALEO ? Pour cela, tu peux nous contacter en remplissant notre formulaire de contact, prendre rendez-vous dans l’agenda d’un de nos conseillers, ou bien nous appeler au 0 806 701 801 !",
          isCollapsed: false,
        },
        {
          question:
            "Pourrais-je modifier mon site internet ALEO après sa création ?",
          answer:
            "Ton site internet évolue en même temps que ton entreprise ! C’est pourquoi nous faisons en sorte chez ALEO de proposer des modifiés le contenu de ton site web de manière ponctuelle afin qu’il représente au mieux ton activité !",
          isCollapsed: false,
        },
        {
          question:
            "J'ai déjà un site vitrine, puis-je quand même faire appel à ALEO ?",
          answer:
            "Si tu as déjà un site internet et du contenu que tu souhaites conserver, nos équipes s’occupent de récupérer les éléments que tu souhaites conserver pendant la migration. Nous faisons en sorte d’assurer une continuité entre ton ancien et ton nouveau site vitrine pour que tu puisses conserver ton image de marque.",
          isCollapsed: false,
        },
      ],
    },
    light: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    collapse(value) {
      const qu = value;
      this.questions.forEach((question) => {
        if (question !== qu) {
          question.isCollapsed = false;
        }
      });
      this.$nextTick(() => {
        value.isCollapsed = !value.isCollapsed;
      });
    },
  },
};
